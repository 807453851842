
div,
body {
  margin: 0;
  padding: 0;
  font-family: roboto, sans-serif;
}
.segundo_contenedor {
    background-color: whitesmoke;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .titulo_segundo_contenedor {
    margin-top: 100px;
    letter-spacing: 0.67px;
    color: #927b57;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    font-size: 3em;
    
  }
  
  .lista_segundo_contenedor {
    margin-top: 200px;
    padding: 50px;
    flex-direction:row;
    align-content: space-around;
    font-size: 1em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-top: 2px solid #c2b8a6;
  }
.tercer_contenedor {
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
    justify-content: center;
  }
  
  /* Imagen del tercer contenedor */
  .imagen_tercer_contenedor {
    width: 100%;
    height: 100%;
    -webkit-filter: saturate(40%);
            filter: saturate(40%);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  }
  
  .tercer_contenedor_bis {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }
  
  .tercer_contenedor_bloque_1 {
    background: #c2b8a6;
    width: 70%;
    height: 100vh;
  }
  
  .tercer_contenedor_bloque_2 {
    background: #927b57;
    width: 30%;
    height: 100vh;
  }
  
  .tercer_contenedor_bloque_3 {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    max-width: 60%;
    max-height: auto;
    padding: 0px;
    top: 10%;
      left: 20%;
  }
  
  /* carrusel */
  .sub_tercer_contenedor_bloque_3 {
    border:solid whitesmoke;
    position: absolute;
    max-width: 60%;
    max-height: auto;
    padding: 0px;
    top: 57.7%;
      left: 50%;
  }
.cuarto_contenedor_bis {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position:relative;
}
  
.cuarto_contenedor_bloque_1 {
  background: #c2b8a6;
  width: 40%;
  height: 100vh;
  justify-content: center;
}
  
.cuarto_contenedor_bloque_2 {
  background-size: cover;
  width: 60%;
  height: 100vh;
}
  
.imagen_cuarto_contenedor {
  width: 100%;
  height: 100vh;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
}
  
.cuarto_contenedor_bloque_3 {
  max-width: 50%;
  max-height: 80%;
  position: absolute;
  bottom: 150px;
  left: 0px;
  padding: 0px;
  margin-left: 40px;
  opacity: 100%;
}

.accordion-item {
  color: #050505;
  background-color: rgb(250, 249, 249);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  opacity: 70%;
  font-weight: bold;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230a58ca'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #fcfdfd;
  --bs-accordion-active-bg: #8491a5;
}
.quinto_contenedor_bis {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position:relative;
  }
  
  .quinto_contenedor_bloque_3 {
    max-width: 40%;
    max-height: 40%;
    position: absolute;
    padding: 0px;
    opacity: 100%;
    position: absolute;
      top: 20%;
      left: 50%;
  }
  
  .imagen_quinto_contenedor {
    width: 100%;
    height: 100%;
    -webkit-filter: saturate(40%);
            filter: saturate(40%);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  }
  
  .quinto_contenedor_bloque_4 {
    width: 30%;
    position: absolute;
    padding: 0px;
    opacity: 100%;
    top: 22%;
      left: 10%;
  }
  
  .footer {
    position: absolute;
    bottom:0;
    background: rgba(128, 128, 128, 0.418);
    opacity: 80%;
    width:100%;
    padding: 1% 0 0 2%;
  }
  
  .logo_footer {
    right: 1%;
    position: absolute;
    max-width: 40px;
    max-width: 40px;
    bottom: 10%;
    right: 50%
  }
  
  .logo_footer:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
.button_home {
    position: fixed;
    bottom: 50px;
    right:50px;
    width: 50px;
    height: 50px;
    opacity: 75%;
    font-size: 1.5em; 
    background-color: #066fe696;
    color: white;
    border: 0px solid white;
    cursor: pointer;
    border-radius: 50px;
  }
  
  .button_home:hover {
    background-color: #066fe6d7;  
    opacity: 100%;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
/* Estilos para el nav bar */
ul {
  position: absolute;
  right: 0;
  Z-index: 1;
  margin: 25px;
  padding: 0;
  display: flex;
}
  
ul li {
  list-style: none;
  padding: 10px 25px;
}
  
ul li a {
  text-decoration: none;
  color: gray;
  font-size: 1.5em;
  position: relative;
  font-weight: bold;
}
  
ul li a:before {
  content: '';
  width: 0px;
  height: 3px;
  background: #00bcd4;
  position: absolute;
  top: 120%;
  left: 0;
  transition: .5s;
}
  
ul li:hover a:before {
  width: 55%;
  -webkit-transform: translateX(100);
          transform: translateX(100);
}


/* Estilos generales para el contenedor principal */
.primer_contenedor {
  width: auto;
  height: 100%;
  flex-wrap: wrap;
}

/* Estilos para la imagen de fondo de la primera pantalla */
.imagen_contenedor_principal {
  -webkit-filter: saturate(40%);
          filter: saturate(40%);
  background-size:cover;
	background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Parrafo de "El Charlten" */
.texto_primer_contenedor {
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  color: #fff;
  display: block;
  position: absolute;
  bottom: 100px;
  left: 0;
  padding: 20px;
  margin-left: 50px;
  width: auto;
}

.parrafo1 {
  margin-left: 110px;
  letter-spacing: 0.67px;
  color: #FFFFFF;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 3em;
}

.parrafo2 {
  margin-left: 20px;
  font-family: roboto, verdana;
  font-weight: 100;
  font-size: 1em;
}

/* Estilos para el logo */
.logo_contenedor_principal {
  max-width: 12%;
  max-height: 12%;
  position: absolute;
  top: 10px;
  left: -40px;
  padding: 10px;
  margin-left: 60px;
  
}
 
/* Estilos para el boton readme */
.button_readme {
  margin-left: 165px;
  height: 50px;
  width: 150px;
  font-size: 1em; /* em= es una unidad relativa. 1.6 veces el tamaño de la letra de su contenedor. Si cambio la letra lo demas se ajusta */
  display: flex;
  flex: 1 1;
  background-color: #066ee6;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  border: 0px solid white;
  cursor: pointer;
  border-radius: 15px;
  -webkit-user-select: none;
          user-select: none;
}

.button_readme:hover {
  background-color: #138ddff1;
}

a {
  text-decoration: none;
}
