.quinto_contenedor_bis {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position:relative;
  }
  
  .quinto_contenedor_bloque_3 {
    max-width: 40%;
    max-height: 40%;
    position: absolute;
    padding: 0px;
    opacity: 100%;
    position: absolute;
      top: 20%;
      left: 50%;
  }
  
  .imagen_quinto_contenedor {
    width: 100%;
    height: 100%;
    filter: saturate(40%);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  }
  
  .quinto_contenedor_bloque_4 {
    width: 30%;
    position: absolute;
    padding: 0px;
    opacity: 100%;
    top: 22%;
      left: 10%;
  }
  
  .footer {
    position: absolute;
    bottom:0;
    background: rgba(128, 128, 128, 0.418);
    opacity: 80%;
    width:100%;
    padding: 1% 0 0 2%;
  }
  
  .logo_footer {
    right: 1%;
    position: absolute;
    max-width: 40px;
    max-width: 40px;
    bottom: 10%;
    right: 50%
  }
  
  .logo_footer:hover {
    transform: scale(1.1);
  }
  