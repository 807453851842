/* Estilos generales para el contenedor principal */
.primer_contenedor {
  width: auto;
  height: 100%;
  flex-wrap: wrap;
}

/* Estilos para la imagen de fondo de la primera pantalla */
.imagen_contenedor_principal {
  filter: saturate(40%);
  background-size:cover;
	background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Parrafo de "El Charlten" */
.texto_primer_contenedor {
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  color: #fff;
  display: block;
  position: absolute;
  bottom: 100px;
  left: 0;
  padding: 20px;
  margin-left: 50px;
  width: auto;
}

.parrafo1 {
  margin-left: 110px;
  letter-spacing: 0.67px;
  color: #FFFFFF;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 3em;
}

.parrafo2 {
  margin-left: 20px;
  font-family: roboto, verdana;
  font-weight: 100;
  font-size: 1em;
}

/* Estilos para el logo */
.logo_contenedor_principal {
  max-width: 12%;
  max-height: 12%;
  position: absolute;
  top: 10px;
  left: -40px;
  padding: 10px;
  margin-left: 60px;
  
}
 
/* Estilos para el boton readme */
.button_readme {
  margin-left: 165px;
  height: 50px;
  width: 150px;
  font-size: 1em; /* em= es una unidad relativa. 1.6 veces el tamaño de la letra de su contenedor. Si cambio la letra lo demas se ajusta */
  display: flex;
  flex: 1;
  background-color: #066ee6;
  margin-top: 8px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;
  border: 0px solid white;
  cursor: pointer;
  border-radius: 15px;
  user-select: none;
}

.button_readme:hover {
  background-color: #138ddff1;
}

a {
  text-decoration: none;
}