.tercer_contenedor {
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100vh;
    justify-content: center;
  }
  
  /* Imagen del tercer contenedor */
  .imagen_tercer_contenedor {
    width: 100%;
    height: 100%;
    filter: saturate(40%);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  }
  
  .tercer_contenedor_bis {
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }
  
  .tercer_contenedor_bloque_1 {
    background: #c2b8a6;
    width: 70%;
    height: 100vh;
  }
  
  .tercer_contenedor_bloque_2 {
    background: #927b57;
    width: 30%;
    height: 100vh;
  }
  
  .tercer_contenedor_bloque_3 {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    max-width: 60%;
    max-height: auto;
    padding: 0px;
    top: 10%;
      left: 20%;
  }
  
  /* carrusel */
  .sub_tercer_contenedor_bloque_3 {
    border:solid whitesmoke;
    position: absolute;
    max-width: 60%;
    max-height: auto;
    padding: 0px;
    top: 57.7%;
      left: 50%;
  }