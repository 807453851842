.button_home {
    position: fixed;
    bottom: 50px;
    right:50px;
    width: 50px;
    height: 50px;
    opacity: 75%;
    font-size: 1.5em; 
    background-color: #066fe696;
    color: white;
    border: 0px solid white;
    cursor: pointer;
    border-radius: 50px;
  }
  
  .button_home:hover {
    background-color: #066fe6d7;  
    opacity: 100%;
    transform: scale(1.1);
  }